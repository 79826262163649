.formcard {
    /* margin-top: 180px; */
    background-color: #fff;
    /* margin-bottom: 48px; */
    margin: auto;
    /* -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.37); */
    word-wrap: break-word;
}

.formcard form {
    /* border-radius: 2%; */
}

/* .accent {
    background-color: #7191e6;
    height: 8px;
} */

