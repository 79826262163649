@media screen and (max-width: 768px) {
  #docimage {
    margin-top: 12px;
  }
  #docname {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
  }
  #doctext {
    font-size: 1rem;
    margin: 4px 0;
  }
  #doceducation,
  #docexperience {
    padding: 0.75rem;
    word-break: break-word;
  }
  #docbutton {
    flex-wrap: wrap;
  }
  #docbutton button {
    padding: 5px;
  }
}

@media screen and (max-width: 1024px) {
  #docinfo {
    padding: 0;
  }
  #doctext {
    font-size: 1rem;
    margin: 4px 0;
  }
  #docbutton button {
    padding: 5px;
  }
}

@media screen and (max-width: 480px) {
  #logo {
    height: 2rem;
    max-width: 80%;
  }
  #companyimage {
    display: none;
  }
  #companyedits {
    padding: 0 2px;
  }
}

@media screen and (max-width: 480px) {
  #product {
    display: block;
  }
  #product h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  #productlink {
    text-align: end;
    margin-bottom: 5px;
  }
  #companydetails {
    padding: 8px;
  }
  #comprofile,
  #doctorprofile {
    padding: 0;
  }
  #combutton h1 {
    font-size: 1rem;
  }
  #docdetails {
    padding: 6px;
  }
  #users {
    display: block;
  }
  #users h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  #userslink {
    text-align: end;
    margin-bottom: 5px;
  }
  #edittexts h2 {
    font-size: 1rem;
  }
  #edittexts button {
    width: 1rem;
    height: 1rem;
  }
  #edittexts button {
    width: 1rem;
    height: 1rem;
  }
}

.overlay {
  position: fixed; /* or absolute if you prefer */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it overlays other content */
}

.overlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  max-height: 100vh;
  overflow: auto;
  width: 100%;
}

.meetoverlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.meetoverlay-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 68vw;
  max-height: 80vh;
  width: 100%;
}

@media screen and (min-width: 380px) and (max-width: 768px){
  .meetoverlay-content{
    max-width: 80vw;
  }
  #companyNames{
    font-size: 1rem;
  }
}


/* mr and sales navbar */

@media screen and (max-width: 480px) {
  #mrlogo,
  #saleslogo {
    height: 2rem;
    max-width: 80%;
  }
  #mrbutton,
  #salesbutton {
    width: 1rem;
    height: 1rem;
    margin: 2px;
  }
  #mricon,
  #salesicon {
    width: 1rem;
    height: 1rem;
  }
  #mrtext,
  #salestext {
    font-size: 14px;
  }
}

/* Hide scrollbar for WebKit browsers */
.conversation-container::-webkit-scrollbar,
.chat-content::-webkit-scrollbar,
.companybox-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for other browsers */
.conversation-container,
.chat-content,
.companybox-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 768px) {
  #hide-on-small-screen {
    display: none;
  }
  #condocname {
    font-size: 14px;
  }
  #conrecent {
    font-size: 12px;
  }
}

/* for tables */

.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.table-container::-webkit-scrollbar {
  height: 2px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #8697c4;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table-row:hover {
  background-color: #f9f9fb;
}

#editablecontent {
  /* height: 500px; */
  overflow: auto;
}


.content {
  display: flex;
  background-color: white;
  color: black;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  border-radius: 25px;
  text-align: center;
}