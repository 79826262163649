/* calendarStyles.css */
/* Customize calendar container */
.react-calendar {
  font-family: Arial, sans-serif;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  width: 100%; /* Slightly increase the width */
  max-width: 600px; /* Set a maximum width to ensure it doesn't get too large */
  margin: 5px auto 0; /* Center the calendar and add margin from the top */
  /* height: 51vh; */
  overflow: auto; /* Add scrollbars if content overflows */
}

/* Center the navigation buttons */
.react-calendar__navigation {
  display: flex;
  justify-content: center; /* Center the buttons */
  align-items: center;
  padding: 5px;
} 

/* Style the calendar navigation buttons */
.react-calendar__navigation button {
  background-color: #6366f1;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  margin: 0 8px; /* Add space between buttons */
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-block; /* Ensure buttons are inline-block */
}

.react-calendar__navigation button:hover {
  background-color: #5252f1;
}

/* Style passed dates */
.react-calendar__tile--passed {
  background-color: #f0f0f0;
  color: #a0a0a0;
  opacity: 0.8;
  border: 1px solid white;
}

.react-calendar__tile--unavailable{
  background-color: #ffcccc; /* Light red for unavailable dates */
  color: #ff0000;
}

/* Style the calendar navigation labels */
.react-calendar__navigation label {
  font-size: 16px;
  color: #333;
}

/* Style calendar weekdays */
.react-calendar__month-view__weekdays {
  background-color: #f0f0f0;
  text-align: center;
}

/* Add gap between days */
.react-calendar__month-view__days {
  border: 1px solid #f0f0f0;
}

.react-calendar__tile--conflict{
  background: #fa3131db;
  color: white;
}

/* Style weekday labels */
.react-calendar__month-view__weekdays__weekday abbr {
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
}

/* Style calendar days */
.react-calendar__tile {
  padding: 12px;
  font-size: 16px;
  transition: background-color 0.3s ease;
  text-align: center; /* Center-align the text */
  border-radius: 4px; /* Add border-radius to make the gap more visible */
  border: 1px solid #f0f0f0;
}

/* Style active calendar day */
.react-calendar__tile--active {
  background-color: #11c911;
  color: #fff;
}

/* Style today's calendar day */
.react-calendar__tile--now {
  background-color: #3730a3;
  color: #fff; /* Ensure text is readable */
  font-weight: bold; /* Make the text bold for better visibility */
}

/* Style calendar day on hover */
.react-calendar__tile:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}