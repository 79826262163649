@media screen and (max-width: 480px) {
  #navlogo {
    height: 2rem;
    max-width: 80%;
  }
  #doctorimg {
    display: none;
  }
  #docdropdown {
    width: 1rem;
    height: 1rem;
  }
  #docicon {
    height: 1rem;
    width: 1rem;
  }
  #editbutton {
    height: 0.75rem;
    width: 0.75rem;
  }
  #edittexts h2{
    font-size: 1rem;
  }
  #edittexts button{
    width: 1rem;
    height: 1rem;
  }
  #edittexts button{
    width: 1rem;
    height: 1rem;
  }
}

/* Hide scrollbar for WebKit browsers */
.conversationlist-container::-webkit-scrollbar,
.doctorbox-content::-webkit-scrollbar, 
.doctorbox-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for other browsers */
.conversationlist-container,
.doctorbox-content,
.doctorbox-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 768px) {
  #hide-on-smallscreen {
    display: none;
  }
  #concomname {
    font-size: 14px;
  }
  #conrecentmsg {
    font-size: 12px;
  }
}

.doctorbox-container {
  display: flex;
  flex-direction: column-reverse;
  height: 100%;
  overflow-y: auto;
}

/* for tables */

.table-container {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.table-container::-webkit-scrollbar {
  height: 2px;
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #8697c4;
  border-radius: 10px;
}

.table-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.table-row:hover {
  background-color: #f9f9fb;
}