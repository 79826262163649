/* Existing styles (unchanged) */
@import url('https://fonts.googleapis.com/css?family=Amaranth|Source+Sans+Pro&display=swap');

.timeline {
  position: relative;
  z-index: 0;
  counter-reset: item;
  padding-left: 0;
  list-style: none;
}

.timeline:before {
  background-color: #f2f2f2;
  content: '';
  height: 86%;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 4px;
  z-index: 0; /* Lower z-index to avoid interference */
}

.timeline-item {
  clear: both;
  list-style-type: none;
  padding: 0 30px;
  height: 80px;
  position: relative;
  margin-bottom: 1rem;
  z-index: 1;
  counter-increment: item; /* Increment the item counter based on the passed value */
}

.timeline-item:before {
  background-color: #d6dbdc;
  border-radius: 50%;
  content: counter(item); /* Display the continuous counter */
  color: #000;
  height: 30px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  z-index: 1;
}

.timeline-content {
  background-color: #f2f2f2;
  color: black;
  width: 40%;
  position: relative;
  z-index: 2; /* Higher z-index to ensure interactivity */
}

.timeline-description {
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes space between items */
}

.timeline-name {
  /* background-color: #d6dbdc; */
  padding: 0.5em;
  margin-right: 1em; /* Adds some space between name and date/time */
  flex-grow: 1; /* Allows the name to take up the remaining space */
  z-index: 2; /* Ensure the name is clickable */
}

.feedback p, .meetingdate {
  padding: 0.5rem;
}

.timeline-item:nth-child(even) .timeline-content {
  float: right;
  margin-right: 5%;
}

.timeline-item:nth-child(odd) .timeline-content {
  float: left;
  margin-left: 5%;
}

/* Expand button styling */
.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  padding: 0;
  position: relative;
  z-index: 1; /* Ensure it's on top */
  pointer-events: auto; /* Ensure it can be clicked */
}

.expand-button.disabled {
  cursor: not-allowed; /* Indicates that the button is not clickable */
  opacity: 0.4;        /* Sets the opacity to 50% */
  pointer-events: none; /* Prevents mouse interactions */
}

.timeline-item.missed .expand-button:hover {
  background-color: #ffdddd;
  color: #bf1313;
  border-radius: 50%;
  padding: 1%;
}

.timeline-item.completed .expand-button:hover {
  background-color: #e0ffe0; 
  color: #4caf50;
  border-radius: 50%;
  padding: 1%;
}

.timeline-item.upcoming .expand-button:hover {
  background-color: #e0f7fa; 
  color: #00bcd4;
  border-radius: 50%;
  padding: 1%;
}

/* Missed items styling */
.timeline-item.missed:before {
  background-color: #bf1313;
  color: #ffdddd;
}

.timeline-item.missed .timeline-content {
  background-color: #ffdddd;
  border: 2px solid #ffdddd;
}

.timeline-item.missed .timeline-description {
  background-color: #bf1313;
  color: #ffdddd;
}

.timeline-item.missed .timeline-details {
  background-color: #ffdddd; /* Match expanded content color */
  z-index: 2; 
}

/* Completed items styling */
.timeline-item.completed:before {
  background-color: #4caf50;
  color: #e0ffe0;
}

.timeline-item.completed .timeline-content {
  background-color: #e0ffe0;
  border: 2px solid #e0ffe0;
}

.timeline-item.completed .timeline-description {
  background-color: #4caf50;
  color: #e0ffe0;
}

.timeline-item.completed .timeline-details {
  background-color: #e0ffe0; /* Match expanded content color */
  z-index: 2; 
}

/* Upcoming items styling */
.timeline-item.upcoming:before {
  background-color: #00bcd4;
  color: #000;
}

.timeline-item.upcoming .timeline-content {
  background-color: #e0f7fa;
  border: 2px solid #e0f7fa;
}

.timeline-item.upcoming .timeline-description {
  background-color: #00bcd4;
  color: #000;
  font-weight: bold;
}

.timeline-item.upcoming .timeline-details {
  background-color: #e0f7fa; /* Match expanded content color */
  z-index: 2; 
}

/* Feedback section */
.timeline-item .feedback {
  background-color: white;
  color: black;
}

/* Customize the scrollbar for the timeline box */
.timeline-box {
  max-height: 440px; /* Set max height to make the box scrollable */
  overflow-y: auto; /* Enable vertical scrolling */
  margin-bottom: 1rem; /* Add space below the timeline box */

  /* Scrollbar width */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #757575 #e0e0e0; /* Scrollbar and track color for Firefox */
}

/* For WebKit browsers (Chrome, Safari) */
.timeline-box::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.timeline-box::-webkit-scrollbar-track {
  background: #f2f2f2; /* Track color */
}

.timeline-box::-webkit-scrollbar-thumb {
  background-color: #666666; /* Scrollbar thumb color */
  border-radius: 10px; /* Round the scrollbar thumb */
}

.timeline-box::-webkit-scrollbar-thumb:hover {
  background-color: #313131; /* Change color when hovering over scrollbar */
}


/* Dots styles */
.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
}

.completed-dot {
  background-color: #4CAF50; /* Green for completed */
}

.missed-dot {
  background-color: #BF1313; /* Red for missed */
}

.upcoming-dot {
  background-color: #00BCD4; /* Skyblue for upcoming */
}

/* Responsive adjustments */
@media screen and (max-width: 968px) {
  .timeline {
    margin: 0 0 0 10px;
  }
  .timeline:before {
    left: 0;
  }
  .timeline-item:before {
    left: 0;
  }
  .timeline-content {
    margin: 2em 0;
    width: 100%;
    float: none !important;
  }

  .timeline-name, .timeline-description {
    width: 100%;
  }

  /* Adjust the scrollable timeline box height for smaller screens */
  .timeline-box {
    max-height: 300px; /* Reduce the height for mobile devices */
  }
}