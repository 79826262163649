/* General styles */
.marquee-container {
  width: 100%; /* Set the width of the marquee container */
  overflow: hidden; /* Hide content that exceeds the container width */
}

.marquee-item {
  display: inline-block; /* Display items in a row */
  vertical-align: middle; /* Align items vertically */
  width: 200px; /* Set the width of each marquee item */
  height: auto; /* Allow height to adjust proportionally */
  margin-right: 20px; /* Optional: Add spacing between items */
}

.marquee-image {
  width: 100%; /* Ensure image fills its container */
  height: auto; /* Maintain aspect ratio */
}

.card {
  position: relative; /* Ensure position context for absolute positioning */
  overflow: hidden; /* Hide any overflow content within the card */
}

.card::before {
  content: ""; /* Create a pseudo-element for the overlay */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Fill the entire width of the card */
  height: 100%; /* Fill the entire height of the card */
  z-index: 1; /* Ensure the overlay is behind other content */
}

.card .hover-overlay {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 16px;
  width: 100%; /* Fill the entire width of the card */
  height: 100%; /* Fill the entire height of the card */
  background-color: rgba(0, 0, 0, 0.87); /* Adjust overlay background color */
  opacity: 0; /* Start with hidden overlay */
  transform: translateY(100%); /* Start overlay below the card */
  transition: opacity 0.7s ease, transform 0.5s ease; /* Transition properties */
  display: flex;
}

.card:hover .hover-overlay {
  opacity: 1; /* Show overlay on hover */
  transform: translateY(0); /* Slide overlay up to show */
}

.hover-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%; /* Set width to 100% */
  text-align: start;
  list-style: disc;
}

li {
  color: white;
  margin-bottom: 10px;
  white-space: pre-line;
}

.hover-container {
  position: relative;
  overflow: hidden;
}

.hover-container .hover-overlay {
  position: absolute;
  bottom: 0; /* Initially, place the overlay at the bottom */
  left: 0;
  right: 0;
  height: 100%; /* Make the overlay cover the entire container */
  background-color: rgb(255, 255, 255); /* Same color as the card */
  opacity: 20;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Transition opacity and transform */
  transform: translateY(
    100%
  ); /* Initially, move the overlay below the container */
}

.hover-container:hover .hover-overlay {
  transform: translateY(0); /* Slide the overlay up from the bottom on hover */
  opacity: 1; /* Adjust the opacity as desired */
}

/* Responsive styles */
@media screen and (max-width: 1024px) {
  .marquee-item {
    width: 150px; /* Adjust the width of marquee items */
  }
  .card .hover-overlay {
    font-size: 14px; /* Adjust font size */
  }
}

@media screen and (max-width: 768px) {
  .marquee-item {
    width: 120px; /* Adjust the width of marquee items */
  }
  .card .hover-overlay {
    font-size: 12px; /* Adjust font size */
    padding: 10px; /* Adjust padding */
  }
  .hover-overlay {
    padding: 10px; /* Adjust padding */
  }
}

@media screen and (max-width: 576px) {
  .marquee-item {
    width: 100px; /* Adjust the width of marquee items */
  }
  .card .hover-overlay {
    font-size: 10px; /* Adjust font size */
    padding: 8px; /* Adjust padding */
  }
  .hover-overlay {
    padding: 8px; /* Adjust padding */
  }
}

@media (max-width: 768px) {
  .slick-slide {
    margin: 0 auto; /* Center the slides */
  }
}

/* Add padding to create space between cards */
.slick-slide {
  padding: 0 10px; /* Adjust the padding as needed */
}

@media screen and (max-width: 1024px) {
  #cardname {
    font-size: 1.75rem;
  }
  #cardinr {
    font-size: 1.5rem;
  }
  #cardbuy1 button {
    padding: 5px 2px;
  }
  #cardbuy2 button {
    padding: 5px 2px;
  }
  #cardbuy3 button {
    padding: 5px 2px;
  }
  #cardbuy4 button {
    padding: 5px 2px;
  }
}

@media screen and (min-width: 1024px) {
  #card {
    height: 50vh;
  }
  #explore {
    height: 80vh;
    width: auto;
  }
}

@media screen and (max-width: 1024px) {
  #card {
    height: 40vh;
  }
}

@media screen and (max-width: 1024px) {
  #card {
    height: 40vh;
  }
}

@media screen and (max-width: 1920px) {
  #card {
    height: 40vh;
  }
}
@media screen and (min-width: 1920px) {
  #card {
    height: 40vh;
  }
}

@media screen and (max-width: 768px) {
  #doctors {
    justify-content: end;
    white-space: nowrap;
  }
  #docotrtitle {
    display: none;
  }
  #doctordiv {
    width: 100%;
  }
  #doctorinput {
    justify-content: center;
  }
  #doctorinput input {
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 5%;
  }
  #centerdiv{
    align-items: center;
  }
  #filterline {
    margin: 0;
  }
}

@media screen and (max-width: 768px) {
  #companies {
    justify-content: end;
    white-space: nowrap;
  }
  #companylist {
    justify-content: end;
    white-space: nowrap;
  }
  #companytitle {
    display: none;
  }
  #companydiv {
    width: 100%;
  }
  #companyinput {
    justify-content: center;
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 5%;
  }
  #companyinput {
    justify-content: center;
  }
  #companyinput input {
    border: none;
    border-bottom: 1px solid gray;
    border-radius: 5%;
  }
  #filterline {
    margin: 0;
  }
}


